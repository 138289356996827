import React, { Component } from "react";
import Slider from "react-slick";
import { withStyles } from "@mui/styles";
import { CardMedia } from "@mui/material";
import { MdClose } from "react-icons/md";
import { concat, size } from "lodash"

const styles = () => ({
    media: {
        objectFit: "contain",
        height: "calc(85vh - 60px)",
    },
    thumbnail: {
        height: 30,
        width: 30,
        objectFit: "cover",
        cursor: "pointer",
        border: "1px solid #337ab7",
    },
});

class GalleryFullScreen extends Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            // Do whatever when esc is pressed
            const { handleClose } = this.props;
            if (handleClose) {
                handleClose();
            }
        }
    }

    render() {
        const { medias, classes, open, handleClose } = this.props;

        if (!medias || !open) {
            return null;
        }

        let imgMedia = [];

        medias.map((media) => {
            if (media && media.url) {
                imgMedia = concat(imgMedia, { url: media.url })
            }
            return null
        })

        if (size(imgMedia) < 1) {
            return null;
        }

        const settings = {
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            arrows: false,
            lazyLoad: true,
            speed: 250,
            slidesToShow: 1,
            slidesToScroll: 1,
            customPaging: (i) => {
                if (imgMedia && imgMedia[i]) {
                    if (imgMedia[i].url) {
                        return (
                            <CardMedia
                                key={`thumb-${Math.random()}`}
                                component="img"
                                className={classes.thumbnail}
                                image={imgMedia[i].url}
                                onError={(e) => {
                                    e.target.src =
                                        "https://def773hwqc19t.cloudfront.net/website/static/no-image-50.png";
                                }}
                            />
                        );
                    }
                }
                return null;
            },
        };

        return (
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: "black", zIndex: 99999 }}>
                <div style={{ position: 'fixed', top: 30, right: 30, zIndex: 88888 }} >
                    <MdClose
                        style={{
                            color: "white",
                            cursor: "pointer"
                        }}
                        size={80}
                        onClick={handleClose}
                    />
                </div>
                <div style={{ position: 'fixed', top: "5%", left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', zIndex: 77777 }} >
                    <Slider key={`slider-${Math.random()}`} {...settings}>
                        {imgMedia.map((media) => {
                            if (media && media.url) {
                                return (
                                    <CardMedia
                                        key={`img-${Math.random()}`}
                                        component="img"
                                        className={classes.media}
                                        image={media.url}
                                        onError={(e) => {
                                            e.target.src =
                                                "https://def773hwqc19t.cloudfront.net/website/static/no-image-300.png";
                                        }}
                                    />
                                );
                            }
                            return null
                        })}
                    </Slider>
                    <style>{`
                        .slick-thumb li img {
                            width: 100%;
                            height: 100%;
                            filter: grayscale(100%);
                        }
                        .slick-thumb li.slick-active img {
                            filter: grayscale(0%);
                        }
                        .slick-dots {
                            margin-top: 10px !important;
                            position: initial !important;
                        }
                        .slick-dots li {
                            width: 37px !important;
                            height: 37px !important;
                            margin: 4px !important;
                        }
                    `}</style>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(GalleryFullScreen);
