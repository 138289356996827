import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Grid, CardMedia, Divider, Hidden } from '@mui/material';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { MdAttachFile } from 'react-icons/md';
import { concat } from 'lodash';
import {
  formatDateLong,
  formatDateCalendar,
  formatDateLongFirstLetterUpper,
  numberOfDays,
  getDateMonth,
  getDateDay,
  getDateStringDay
} from '../utils/dates';
import { formattingTextToHTML } from '../utils/rendering';
import DialogBox from './DialogBox';
import Gallery from './Gallery';
import CalendarButton from '../utils/CalendarButton';
import CalendarModal from '../utils/CalendarModal';
import { widgetTypes } from '../constants';

const calculateSpacing = () => {
  const factor = window.zoomEvent ? window.zoomEvent : 1;
  if (window.containerSizeEvent === 'small') return 4 * factor;
  if (window.containerSizeEvent === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 8 * factor;
};

const calculateMarginBottom = () => {
  const factor = window.zoomEvent ? window.zoomEvent : 1;
  if (window.containerSizeEvent === 'small') return 8 * factor;
  if (window.containerSizeEvent === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 10 * factor;
};

const calculateZoom = () => {
  if (window.displayModeEvent === 'slick') return 1;
  if (window.zoomEvent) return window.zoomEvent;
  return 1;
};

const calculateImageMaxHeight = () => {
  if (window.displayModeEvent === 'slick') return 0;
  if (parseInt(window.imageMaxHeightEvent, 10)) return parseInt(window.imageMaxHeightEvent, 10)
  return null;
};

const spacing = calculateSpacing();
const marginBottom = calculateMarginBottom();
const zoom = calculateZoom();
const imageMaxHeight = calculateImageMaxHeight();

class EventsCard extends PureComponent {
  state = {
    open: false,
  };

  displayDate = (startDate, endDate) => {
    if (startDate === endDate) {
      return formatDateLong(startDate);
    }
    return `${formatDateLong(startDate)} - ${formatDateLong(endDate)}`;
  };

  displayMapLink = (label, latitude, longitude) => {
    const link =
      'https://www.google.com/maps/search/?api=1&query=' +
      latitude +
      ',' +
      longitude;
    return (
      <a target="_blank" href={link} rel="noopener noreferrer">
        {label}
      </a>
    );
  };

  generateCardMedia = event => {
    return (
      <CardMedia
        component="img"
        alt={event.title}
        image={event.image}
        title={event.title}
      />
    );
  };

  generateImageBlock = medias => {
    const { classes, event, diaporama } = this.props;
    if (diaporama) {
      return (
        <CardMedia
          component="img"
          alt={event.title}
          className={classes.image}
          style={imageMaxHeight ? { maxHeight: imageMaxHeight } : {}}
          image={event.image}
          title={event.title}
          onClick={this.handleClickOpen}
        />
      );
    }
    return <Gallery medias={medias} type={widgetTypes[0]} />;
  };

  generateMainTextBlock = () => {
    const { classes, event } = this.props;
    return (
      <div>
        <Hidden smDown>
          <Typography align="left" className={classes.title} variant="h4">
            {event.title}
          </Typography>
        </Hidden>
        <Grid item xs={10} sm={11} style={{}}>
          <Typography
            align="left"
            className={classes.date}
            variant="body1"
          >
            {this.displayDate(event.start_date, event.end_date)}
          </Typography>
        </Grid>

        <div className={classes.liseret} style={{ marginTop: 20 }} />

        <Typography
          align="left"
          className={classes.description}
          variant="body1"
          component="div" // IMPORTANT pour ne pas se retrouver avec des blaises interdites dans le tag <p>
          dangerouslySetInnerHTML={{
            __html: formattingTextToHTML(event.description),
          }}
        />

        {event.author ? (
          <Typography
            align="left"
            className={classes.description}
            variant="body1"
            style={{
              fontStyle: 'italic',
              lineHeight: 'normal',
              fontSize: 14 * zoom,
            }}
          >
            {'Publié par ' + event.author}
          </Typography>
        ) : null}

        {event.attachment ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{ marginBottom }}
          >
            <MdAttachFile
              style={{
                alignItems: 'center',
                color: '#000000',
              }}
              size={20 * zoom}
            />
            <Grid item xs={10}>
              <Typography
                align="left"
                style={{
                  color: '#666',
                  marginLeft: 10,
                  fontSize: 14 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
                component="div"
              >
                <a
                  href={event.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit' }}
                >
                  {'Voir la pièce jointe'}{event.attachment && event.attachment.split('.').pop() ? ' (' + event.attachment.split('.').pop() + ')' : ''}
                </a>
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  };

  generateMoreInfoBlock = () => {
    const { event, diaporama, classes } = this.props;

    const eventCalendar = {
      description: event.description,
      duration: 24 + 24 * numberOfDays(event.end_date, event.start_date),
      endDatetime: formatDateCalendar(event.end_date) + 'T200000',
      location: event.address_label,
      startDatetime: formatDateCalendar(event.start_date) + 'T080000',
      title: event.title,
      timezone: 'Europe/Paris',
    };

    const AddToCalendarModal = AddToCalendarHOC(CalendarButton, CalendarModal);

    return (
      <div>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ paddingBottom: 10, paddingTop: 8 }}
        >
          <Grid item xs={12}>
            <AddToCalendarModal
              className={classes.addToCalendarComponent}
              linkProps={{
                className: classes.addToCalendarLink,
              }}
              buttonText="Ajouter au calendrier"
              event={eventCalendar}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.labelDescription}
              variant="body2"
            >
              {event.start_date !== event.end_date ? "DATES" : "DATE"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {event.start_date !== event.end_date ? (
              <Typography
                align="left"
                style={{
                  color: "black",
                  wordWrap: "break-word",
                  fontSize: 14 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
              >
                Du : {formatDateLongFirstLetterUpper(event.start_date)} <br />
                Au : {formatDateLongFirstLetterUpper(event.end_date)}
              </Typography>
            ) : (
              <Typography
                align="left"
                style={{
                  color: "black",
                  wordWrap: "break-word",
                  fontSize: 14 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
              >
                {formatDateLongFirstLetterUpper(event.start_date)}
              </Typography>
            )}

          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.labelDescription}
              variant="body2"
            >
              LIEU
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.linkColor}
              variant="body2"
            >
              {this.displayMapLink(
                event.address_label,
                event.latitude,
                event.longitude
              )}
            </Typography>
          </Grid>
        </Grid>

        {event.schedule ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{
              paddingBottom: 10,
            }}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                className={classes.labelDescription}
                variant="body2"
              >
                HORAIRES
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="left"
                style={{
                  color: "black",
                  wordWrap: "break-word",
                  fontSize: 14 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: formattingTextToHTML(event.schedule, true),
                }}
              />
            </Grid>
          </Grid>
        ) : null}


        {event.price ? (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            style={{ paddingBottom: 10, }}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                className={classes.labelDescription}
                variant="body2"
              >
                TARIFS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="left"
                style={{
                  color: "black",
                  wordWrap: "break-word",
                  fontSize: 14 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: formattingTextToHTML(event.price, true),
                }}
              />
            </Grid>
          </Grid>
        ) : null}


        {!diaporama && (event.url1 || event.url2 || event.url3) ? (
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            style={{ marginBottom }}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                style={{
                  color: '#2DA192',
                  fontSize: 15 * zoom,
                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
                variant="body1"
              >
                {"PLUS D'INFORMATIONS"}
              </Typography>
            </Grid>
            {event.url1 ? (
              <Grid item xs={12}>
                <Typography
                  align="left"
                  style={{
                    color: '#666',
                    fontSize: 14 * zoom,
                    lineHeight: 'normal',
                    marginTop: 5
                  }}
                  noWrap
                  variant="body2"
                >
                  <a
                    href={event.url1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Voir le lien'}
                  </a>
                </Typography>
              </Grid>
            ) : null}

            {event.url2 ? (
              <Grid item xs={12}>
                <Typography
                  align="left"
                  style={{
                    color: '#666',
                    fontSize: 14 * zoom,
                    lineHeight: 'normal',
                    marginTop: 5
                  }}
                  noWrap
                  variant="body2"
                >
                  <a
                    href={event.url2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Voir le lien 2'}
                  </a>
                </Typography>
              </Grid>
            ) : null}
            {event.url3 ? (
              <Grid item xs={12}>
                <Typography
                  align="left"
                  style={{
                    color: '#666',
                    fontSize: 14 * zoom,
                    lineHeight: 'normal',
                    marginTop: 5
                  }}
                  noWrap
                  variant="body2"
                >
                  <a
                    href={event.url3}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Voir le lien 3'}
                  </a>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </div>
    );
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  onHandleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, event, diaporama, popupCarousel } = this.props;
    if (!event) return null;

    let medias = [];
    if (event.videos) {
      medias = concat(medias, event.videos);
    }
    if (event.image) {
      medias = concat(medias, { url: event.image });
    }
    if (event.gallery) {
      medias = concat(medias, event.gallery);
    }

    const { open } = this.state;

    const hideImage = imageMaxHeight === -1;

    // Si fullPage ou diapo on affiche sur trois colonnes pour les grands écrans
    let smallContainer = false;
    let largeContainer = false;

    if (window.isDiapo === 'true') {
      smallContainer = false;
      largeContainer = true;
    } else if (popupCarousel) {
      smallContainer = false;
      largeContainer = true;
    } else {
      if (window.containerSizeEvent === 'small') {
        smallContainer = true;
        largeContainer = false;
        // console.log('containerSizeEvent is ' + window.containerSizeEvent);
      }
      if (window.containerSizeEvent === 'medium') {
        smallContainer = false;
        largeContainer = false;
        // console.log('containerSizeEvent is ' + window.containerSizeEvent);
      }
      if (window.containerSizeEvent === 'large') {
        smallContainer = false;
        largeContainer = true;
        // console.log('containerSizeEvent is ' + window.containerSizeEvent);
      }
    }

    return (
      <div>
        <Grid
          container
          spacing={0}
          style={{ paddingRight: spacing, paddingLeft: spacing }}
        >
          <Hidden smUp>
            <Grid item xs={12} style={{}}>
              <Typography
                align="left"
                className={classes.title}
                variant="body1"
              >
                {event.title}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          {!hideImage ?
            (
              <Grid
                item
                xs={12}
                sm={(() => {
                  if (smallContainer) return 12;
                  return 5;
                })()}
                md={(() => {
                  if (smallContainer) return 12;
                  return 4;
                })()}
                lg={(() => {
                  if (largeContainer) return 3;
                  if (smallContainer) return 12;
                  return 4;
                })()}
                style={{ paddingRight: spacing, paddingLeft: spacing }}
              >
                <Grid>
                  {event.start_date === event.end_date ? (
                    <Grid container alignItems="center" justifyContent="center" style={{ paddingTop: 5 }}>
                      <Grid item xs={5}>
                        <div className={classes.calendDate}>
                          <span className={classes.binds}></span>
                          <Typography
                            className={classes.month}
                            variant="body2"
                          >
                            {getDateMonth(event.start_date)}
                          </Typography>
                          <div className={classes.day}>
                            <Typography
                              variant="h6"
                              className={classes.dayNumber}
                            >
                              {getDateDay(event.start_date)}
                            </Typography>
                            <Typography
                              variant="body2"
                            >
                              {getDateStringDay(event.start_date)}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container alignItems="center" justifyContent="center" style={{ paddingTop: 5 }}>
                      <Grid item xs={5}>
                        <div className={classes.calendDate} style={{ float: "right" }}>
                          <span className={classes.binds}></span>
                          <Typography
                            className={classes.month}
                            variant="body2"
                          >
                            {getDateMonth(event.start_date)}
                          </Typography>
                          <div className={classes.day}>
                            <Typography
                              variant="h6"
                              className={classes.dayNumber}
                            >
                              {getDateDay(event.start_date)}
                            </Typography>
                            <Typography
                              variant="body2"
                            >
                              {getDateStringDay(event.start_date)}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "center" }}>
                        au
                      </Grid>
                      <Grid item xs={5}>
                        <div className={classes.calendDate} style={{ float: "left" }}>
                          <span className={classes.binds}></span>
                          <Typography
                            className={classes.month}
                            variant="body2"
                          >
                            {getDateMonth(event.end_date)}
                          </Typography>
                          <div className={classes.day}>
                            <Typography
                              variant="h6"
                              className={classes.dayNumber}
                            >
                              {getDateDay(event.end_date)}
                            </Typography>
                            <Typography
                              variant="body2"
                            >
                              {getDateStringDay(event.end_date)}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {this.generateImageBlock(medias)}
              </Grid>
            ) : null}
          <Grid
            item
            xs={12}
            sm={(() => {
              if (smallContainer || hideImage) return 12;
              return 7;
            })()}
            md={(() => {
              if (smallContainer || hideImage) return 12;
              return 8;
            })()}
            lg={(() => {
              if (smallContainer || hideImage) return 12;
              if (largeContainer) return 9;
              return 8;
            })()}
            style={{ paddingRight: spacing, paddingLeft: spacing }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={(() => {
                  if (largeContainer) return 7;
                  return 12;
                })()}
                style={{ paddingRight: spacing, paddingLeft: spacing }}
              >
                {this.generateMainTextBlock()}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={(() => {
                  if (largeContainer) return 5;
                  return 12;
                })()}
                style={{ paddingRight: spacing, paddingLeft: spacing }}
              >
                {this.generateMoreInfoBlock()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogBox
          open={open}
          content={this.generateCardMedia(event)}
          title={event.title}
          handleClose={this.onHandleClose}
        />
        {!diaporama && !popupCarousel ? (
          <Divider
            variant="middle"
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        ) : null}
      </div>
    );
  }
}

EventsCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  event: PropTypes.objectOf(PropTypes.object()).isRequired,
  diaporama: PropTypes.bool,
  popupCarousel: PropTypes.bool,
};

const styles = () => ({
  date: {
    fontSize: 16 * zoom,
    color: "#565656",
    fontStyle: "italic",
  },
  title: {
    color: '#333',
    fontWeight: 'bold',
    fontSize: 30 * zoom,
  },
  labelDescription: {
    color: "#003057",
    width: "max-content",
    fontSize: 14 * zoom,
    fontWeight: "bold"
  },
  description: {
    fontSize: 16 * zoom,
    color: '#333',
    marginBottom,
    wordBreak: 'break-word',
  },
  linkColor: {
    color: "#0056a4",
    fontSize: 14 * zoom,
    lineHeight: 'normal',
    wordBreak: 'break-all',
  },
  image: {
    cursor: 'pointer',
    marginBottom,
  },
  addToCalendarComponent: {
    marginLeft: 0,
  },
  addToCalendarLink: {
    marginLeft: 10,
    marginRight: 10,
    color: '#2869E5',
    textDecoration: 'none',
    fontSize: 16,
  },
  calendDate: {
    display: "block",
    width: 80,
    margin: "0 auto 30px",
    background: "#fff",
    textAlign: "center",
    fontFamily: "'Helvetica', sans-serif",
    position: "relative",
  },
  binds: {
    position: "absolute",
    height: 12,
    width: 60,
    background: "transparent",
    border: "2px solid #999",
    borderWidth: "0 5px",
    top: -6,
    left: 0,
    right: 0,
    margin: "auto",
  },
  month: {
    background: "#d10000",
    display: "block",
    padding: "8px 0 0",
    color: "#fff",
    fontWeight: "bold",
    borderBottom: "2px solid #333",
    boxShadow: "inset 0 -1px 0 0 #666",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  day: {
    display: "block",
    margin: 0,
    boxShadow: "0 2px 3px #ccc",
    position: "relative",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5
  },
  dayNumber: {
    height: 25,
    marginTop: -3,
    fontWeight: "bold"
  },
  liseret: {
    borderTop: "solid 1px #d0d0d0",
    width: "3%",
    margin: "10px 0 10px"
  }
});

export default withStyles(styles)(EventsCard);
