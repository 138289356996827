import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import { isEmpty } from "lodash";
import EventsCardCarousel from './EventsCardCarousel';


/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role,jsx-a11y/no-noninteractive-element-interactions */
function LeftNavButton(props) {
    const { className, onClick, currentSlide } = props;
    if (currentSlide === 0) return null;
    return (
        <img
            src={
                "https://def773hwqc19t.cloudfront.net/website/static/left-arrow.png"
            }
            onClick={onClick}
            onKeyDown={onClick}
            className={className}
            style={{ width: 45, height: 45, left: -50 }}
            alt={"Précédent"}
        />
    );
}

function RightNavButton(props) {
    const { className, clickNext } = props;
    return (
        <img
            src={
                "https://def773hwqc19t.cloudfront.net/website/static/right-arrow.png"
            }
            onClick={clickNext}
            onKeyUp={clickNext}
            className={className}
            style={{ width: 45, height: 45, right: -50 }}
            alt={"Suivant"}
        />
    );
}

class EventSlider extends React.Component {
    goNext = () => {
        if (this.slider) {
            this.slider.slickNext();
        }
    };

    render() {
        const { event, theme } = this.props;

        if (isEmpty(event)) {
            return null;
        }

        // Si fullPage ou diapo on affiche sur trois colonnes pour les grands écrans
        let smallContainer = false;
        let largeContainer = false;

        if (window.containerSizeEvent === 'small') {
            smallContainer = true;
            largeContainer = false;
        }
        if (window.containerSizeEvent === 'medium') {
            smallContainer = false;
            largeContainer = false;
        }
        if (window.containerSizeEvent === 'large') {
            smallContainer = false;
            largeContainer = true;
        }

        const settings = {
            dots: false,
            arrows: true,
            infinite: false, // Bug when infinite: slider broken when infinite and slidesToShow > number of slides https://github.com/akiran/react-slick/issues/1182
            speed: 1000,
            slidesToShow: (() => {
                if (smallContainer) { return 1 }
                if (!smallContainer && !largeContainer) { return 2 }
                return 4;
            })(),
            slidesToScroll: (() => {
                if (smallContainer) { return 1 }
                if (!smallContainer && !largeContainer) { return 2 }
                return 2;
            })(),
            lazyLoad: true,
            autoplay: window.speedEvent && window.speedEvent > 0,
            autoplaySpeed: window.speedEvent ? window.speedEvent * 1000 : 5000,
            nextArrow: <RightNavButton clickNext={() => this.goNext()} />,
            prevArrow: <LeftNavButton />,
            // className: "slider",
            responsive: [
                {
                    breakpoint: theme.breakpoints.values.sm,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.md,
                    settings: {
                        slidesToShow: (() => {
                            if (smallContainer) return 1;
                            return 2;
                        })(),
                        slidesToScroll: (() => {
                            if (smallContainer) return 1;
                            return 2;
                        })(),
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.lg && theme.breakpoints.values.xl,
                    settings: {
                        slidesToShow: (() => {
                            if (smallContainer) return 1;
                            if (largeContainer) return 3;
                            return 2;
                        })(),
                        slidesToScroll: (() => {
                            if (smallContainer) return 1;
                            if (largeContainer) return 3;
                            return 2;
                        })(),
                    },
                },
            ],
        };

        if (this.slider) {
            this.slider.slickGoTo(0, false);
        }

        return (
            <Slider
                style={{ backgroundColor: '#00000' }}
                ref={(slider) => {
                    this.slider = slider
                }}
                {...settings}>
                {event.map((item) => (
                    <EventsCardCarousel event={item} key={item.id} />
                ))}
            </Slider>
        );
    }
}

EventSlider.propTypes = {
    theme: PropTypes.objectOf(PropTypes.object()).isRequired,
};

export default EventSlider;